
import { reactive, defineComponent, computed } from 'vue'
import Appbar from './components/Appbar.vue'
import NavSidebar from './components/NavSidebar.vue'
import { getUserName } from '@/composables/extractFromJwt'
import router from '@/router'
import IdleWarning from '@/components/IdleWarning.vue'

export default defineComponent({
  name: 'App',
  components: {
    Appbar,
    NavSidebar,
    IdleWarning,
  },

  computed: {
    xsBreakpoint() {
      return this.$vuetify.display.xs
    },
  },

  setup() {
    const state: any = reactive({
      navDrawer: true,
      fullName: '',
      authRoute: true,
      registration: null,
      updateExists: false,
      refreshing: false,
    })

    const getCurrentRoute = computed(() => {
      return router.currentRoute.value.name
    })

    const toggleNav = () => {
      state.navDrawer = !state.navDrawer
    }

    const userFullName = () => {
      const userInfo = getUserName()
      if (!userInfo) return ''

      const { given_name, family_name } = userInfo
      const buildFullName = given_name ? `${given_name} ` : ''
      const fullName = family_name ? `${buildFullName}${family_name}` : buildFullName.trim()
      return fullName
    }

    const userAuthRoute = (route: string): void => {
      switch (route) {
        case 'Login':
          state.authRoute = true
          break
        case 'ForgotPassword':
          state.authRoute = true
          break
        case 'UpdatePasswordWithCode':
          state.authRoute = true
          break
        default:
          state.authRoute = false
      }
    }

    const updateAvailable = (event: any) => {
      state.registration = event.detail
      state.updateExists = true
    }
    const refreshApp = (): void => {
      state.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!state.registration || !state.registration.waiting) return
      // Send message to SW to skip the waiting and activate the new SW
      state.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }

    return { state, toggleNav, userFullName, userAuthRoute, getCurrentRoute, updateAvailable, refreshApp }
  },
  mounted() {
    this.$router.afterEach((to: any) => {
      this.userAuthRoute(to.name)
    }),
      document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.state.refreshing) return
      this.state.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  },
})
