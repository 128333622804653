
import { defineComponent, reactive } from 'vue'
import { ApiSetup } from '@/interfaces/api'
import { apiUrl } from '../services/apiUrls'
import setupHeaders from './composables/setupHeaders'
import addParams from './composables/addParams'
import useBaseService from './composables/useBaseService'

export default defineComponent({
  name: 'UserService',
  setup() {
    const state = reactive({
      alertMessage: 'An issue contacting the server has occured',
      serverErrorMessage: '',
      serverErrors: [],
      errorCode: '',
      showAlert: false,
      loading: false,
      isUserService: true,
    })
    const { performFetch } = useBaseService

    const userLogin = (setup: ApiSetup) => {
      state.showAlert = false
      const { params, additionalHeaders, method, body, useAutoLoadingIndicator } = setup
      let url = apiUrl('USER_LOGIN', 'emp/login')
      url = addParams(url, params)

      if (useAutoLoadingIndicator) {
        state.loading = true
      }

      let headers = new Headers()
      headers.append('Content-Type', 'application/json')
      if (additionalHeaders) {
        for (const i in additionalHeaders) {
          headers.append(i, additionalHeaders[i])
        }
      }

      const requestOptions = new Request(url.toString(), { method, body, headers })

      return performFetch(requestOptions, true, true, true, state)
    }

    const updatePasswordWithCode = (setup: ApiSetup) => {
      state.showAlert = false
      const { params, additionalHeaders, method, body, useAutoLoadingIndicator } = setup
      let url = apiUrl('UPDATE_PASSWORD_WITH_CODE', 'emp/forgotpassword')
      url = addParams(url, params)

      if (useAutoLoadingIndicator) {
        state.loading = true
      }

      let headers = new Headers()
      headers.append('Content-Type', 'application/json')
      if (additionalHeaders) {
        for (const i in additionalHeaders) {
          headers.append(i, additionalHeaders[i])
        }
      }
      const requestOptions = new Request(url.toString(), { method, body, headers })

      return performFetch(requestOptions, true, true, true, state)
    }

    const requestPasswordResetCode = (setup: ApiSetup) => {
      state.showAlert = false
      const { params, additionalHeaders, method, body, useAutoLoadingIndicator } = setup

      let url = apiUrl('FORGOT_PASSWORD', 'emp/forgotpassword')
      url = addParams(url, params)

      if (useAutoLoadingIndicator) {
        state.loading = true
      }

      let headers = new Headers()
      headers.append('Content-Type', 'application/json')
      if (additionalHeaders) {
        for (const i in additionalHeaders) {
          headers.append(i, additionalHeaders[i])
        }
      }
      const requestOptions = new Request(url.toString(), { method, body, headers })

      return performFetch(requestOptions, true, true, true, state)
    }

    const updatePassword = async (setup: ApiSetup) => {
      state.showAlert = false
      const { params, additionalHeaders, method, body, useAutoLoadingIndicator } = setup

      let url = apiUrl('UPDATE_PASSWORD_WITH_CODE', 'emp/updatepassword')
      url = addParams(url, params)

      if (useAutoLoadingIndicator) {
        state.loading = true
      }

      const headers = await setupHeaders(additionalHeaders)
      const requestOptions = new Request(url.toString(), { method, body, headers })

      return performFetch(requestOptions, true, true, true, state)
    }

    const checkUserSso = (setup: ApiSetup) => {
      state.showAlert = false
      const { params, additionalHeaders, method, body, useAutoLoadingIndicator } = setup
      let url = apiUrl('SSO_VALIDATE', 'emp/sso/verify')
      url = addParams(url, params)

      if (useAutoLoadingIndicator) {
        state.loading = true
      }

      let headers = new Headers()
      headers.append('Content-Type', 'application/json')
      if (additionalHeaders) {
        for (const i in additionalHeaders) {
          headers.append(i, additionalHeaders[i])
        }
      }
      const requestOptions = new Request(url.toString(), { method, body, headers})

      return performFetch(requestOptions, true, true, true, state)
    }

    const ssoLogin = (setup: ApiSetup) => {
      state.showAlert = false
      const { params, additionalHeaders, method, body, useAutoLoadingIndicator } = setup
      let url = apiUrl('SSO_LOGIN', 'emp/sso/login')
      url = addParams(url, params)

      if (useAutoLoadingIndicator) {
        state.loading = true
      }

      let headers = new Headers()
      headers.append('Content-Type', 'application/json')
      if (additionalHeaders) {
        for (const i in additionalHeaders) {
          headers.append(i, additionalHeaders[i])
        }
      }
      const requestOptions = new Request(url.toString(), { method, body, headers })

      return performFetch(requestOptions, true, true, true, state)
    }

    return { state, userLogin, updatePasswordWithCode, requestPasswordResetCode, updatePassword,checkUserSso ,ssoLogin}
  },
})
