
import { ref, defineComponent } from 'vue'
export default defineComponent({
  name: 'ConfirmationDialog',
  props: {
    title: {
      type: String,
      default: 'Confirm Data Source Change',
    },
    text: {
      type: String,
      default: 'There might be some data loss. Do you want to continue?',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    cancelTitle: {
      type: String,
      default: 'Cancel',
    },
    continueTitle: {
      type: String,
      default: 'Continue',
    },
  },
  emits: ['cancelClicked', 'continueClicked'],
  setup(props, { emit }) {
    const showDialog = ref(false)
    function handleCancel() {
      emit('cancelClicked')
    }

    function handleContinue() {
      emit('continueClicked')
    }

    return { handleCancel, handleContinue, showDialog, props }
  },
})
