import { ref, Ref } from 'vue'
import router from '@/router'
import { getResources } from '@/composables/extractFromJwt'
import { apiUrl } from '@/services/apiUrls'

/**
 * tokenRefreshState
 * 
 * 'available' | 'pending' | 'error'
 *   available - allow a new request 
 *   pending - A request for a new token is pending. In this state, all new requests for a new token
 *             will wait for the already requested token to return, then these requests will use the 
 *             new token, not making a new token request
 *   error - The refresh token API call had an error
 */
export const tokenRefreshState: Ref<string> = ref('available')

/**
 * refreshToken
 * 
 * Only one refresh token API request allowed at a time
 * During pending token API request; all new requests are on-hold until initial request resolves
 * After initial request resolves, new requests that were on-hold are resolved and allowed to use the new
 * token for the pending api calls
 * @returns Promise<any>
 */
const refreshToken = (): Promise<any> => {
  if (tokenRefreshState.value !== 'pending') {
    tokenRefreshState.value = 'pending'
  } else {
    return new Promise((resolve, reject) => {
      const intervalId = setInterval(() => {
        if (tokenRefreshState.value === 'available') {
          clearInterval(intervalId)
          resolve('Success')
        }
        if (tokenRefreshState.value === 'error') {
          clearInterval(intervalId)
          reject('Error')
        }
      }, 1000)
    })
  }

  const identityToken: string | null = localStorage.getItem('identityToken')
  const refreshToken: string | null = localStorage.getItem('refreshToken')

  const payloadBody = {
    identityToken: identityToken,
    refreshToken: refreshToken,
  }

  const method = 'POST'
  const body = JSON.stringify(payloadBody)

  const url = apiUrl('USER_REFRESH', 'emp/refresh')
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const requestOptions = new Request(url.toString(), { method, body, headers })

  return performFetch(requestOptions)
}

/**
 * handleError
 *
 * Handle responses that are not in the 200 HTTP status range by throwing error
 * this error gets handled by the fetch catch block below
 * For 200 HTTP status range responses; return response in JSON format
 */
const handleError = async (res: Response): Promise<any> => {
  if (!res.ok) {
    const resToRead = res.clone()
    const errorBody = await resToRead.json()
    const combined = Object.assign(errorBody, { statusCode: res.status })
    throw combined
  } else {
    return res.json()
  }
}

const setUserResources = (): void => {
  const resources = getResources()
  if (resources) {
    localStorage.setItem('resources', resources)
  } else {
    localStorage.removeItem('resources')
    router.push('no-access')
  }
}

/**
 * refreshToken-performFetch
 * No loading indicator needed - The triggering api call will already have loading indicator
 * No error messaging needed - If the refesh api fails; take user to login
 */ 
const performFetch = (requestOptions: Request): Promise<any> => {
  return fetch(requestOptions)
    .then(handleError)
    .then((data) => {
      const { identityToken, refreshToken } = data
      localStorage.setItem('identityToken', identityToken)
      localStorage.setItem('refreshToken', refreshToken)
      tokenRefreshState.value = 'available'
      setUserResources()
      return data
    })
    .catch((error) => {
      localStorage.removeItem('identityToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('resources')
      tokenRefreshState.value = 'error'
      router.push({ name: 'Login' })
      throw 'skip-additional-error-handling'
    })
}

export default refreshToken
