import { apiUrl } from '@/services/apiUrls'
import { ref } from 'vue'
import setupHeaders from '@/services/composables/setupHeaders'
import handleError from '@/services/composables/handleError'

let grants: any = undefined

export const getEmpGrants = async () => {
  if (!grants) {
    const url = apiUrl('EMP_GRANTS', 'acl/grants/emp')
    const additionalHeaders = null

    const headers = await setupHeaders(additionalHeaders)
    const requestOptions = new Request(url.toString(), { method: 'GET', body: null, headers })
    return fetch(requestOptions)
      .then(handleError)
      .then((data) => {
        grants = data.data
        return data
      })
      .catch((error) => {
        throw error
      })
  } else {
    return new Promise((resolve, reject) => {
      resolve({ data: grants })
    })
  }
}

const checkGrant = <T>(resourceName: string, grant: string) => {
  const check = ref(false)
  if (grants) {
    const pageGrant: string[] = grants[resourceName]
    if (pageGrant && pageGrant.includes(grant)) {
      check.value = true
    } else {
      check.value = false
    }
  }

  const getUpdatedGrants = async () => {
    const { data } = await getEmpGrants()
    grants = data
    const pageGrant: string[] = grants[resourceName]
    if (pageGrant && pageGrant.includes(grant)) {
      check.value = true
    } else {
      check.value = false
    }
  }

  if (!grants) {
    getUpdatedGrants()
  }

  return check
}

export default checkGrant
