const routes = [
  {
    name: 'Operations',
    path: '/operations',
    component: () => import('./pages/OperationsBase.vue'),
    meta: {
      pageTitle: 'Operations',
      requiresAuth: true,
      resourceName: 'CNTR-OPER',
    },
    redirect: { name: 'OperationsKennel' },
    children: [
      {
        name: 'OperationsKennel',
        path: 'kennels',
        component: () => import('./pages/OperationsKennel.vue'),
      },
      {
        name: 'OperationsEmployees',
        path: 'employees',
        component: () => import('./pages/OperationsEmployees.vue'),
      },
      {
        name: 'OperationsReservation',
        path: 'reservation',
        component: () => import('./pages/OperationsReservation.vue'),
      },
      {
        name: 'OperationsAddOns',
        path: 'add-ons',
        component: () => import('./pages/OperationsAddOns.vue'),
      },
    ],
  },
]

export default routes
