
import { defineComponent } from 'vue'
import filterNav from '@/composables/filterList'
import nav from '@/assets/main-nav.json'
import { useDisplay } from 'vuetify'

export default defineComponent({
  name: 'NavSidebar',
  props: ['modelValue', 'navType'],
  setup(props, { emit }) {
    let resources = localStorage.getItem('resources')
    let navList = filterNav(nav, resources)
    const { smAndUp } = useDisplay()

    const itemClicked = () => {
      if (!smAndUp.value) {
        emit('update:modelValue', !props.modelValue)
      }
    }
    return { navList, itemClicked }
  },
})
