import { reactive } from 'vue'
import { useScrollToTop } from '@/composables/scrollToTop'
import handleError from './handleError'

const raisePageRegionError = (typeOfMessage: string = '', error: any = null, state: any): void => {
  state.serverErrorMessage = ''
  state.serverErrors = []
  state.errorCode = ''
  state.alertMessage = ''

  if (typeOfMessage === 'useServerErrors' && error?.message) {
    state.serverErrorMessage = error?.message
    state.serverErrors = error?.errors
    state.errorCode = error?.errorCode
    if (state.isUserService) {
      state.alertMessage = error?.message
    }
  } else {
    state.alertMessage = 'An issue contacting the server has occured'
  }
  state.showAlert = true
  useScrollToTop()
}
/**
 * performFetch
 *
 * Handle API errors -
 * Optionally, raise the page region error banner for network, 400, and 500 level errors
 * - network failure, anything that prevents the request from completing,
 *   anything that does not return a http status code
 * - 400 level and 500 level errors - defaults to handling here and showing the banner
 *
 * return error | 'skip-additional-error-handling'
 *
 * When returning 'skip-additional-error-handling' -
 * This allows the calling function to know an error banner already has been displayed to the user
 */
const performFetch = (
  requestOptions: Request,
  autoNetErrors: boolean = true,
  auto4xxErrors: boolean = true,
  auto5xxErrors: boolean = true,
  state: any
) => {
  return fetch(requestOptions)
    .then(handleError)
    .then((data) => {
      return data
    })
    .catch((error) => {
      // Network failure
      if (!error?.statusCode && autoNetErrors) {
        raisePageRegionError('', null, state)
        throw 'skip-additional-error-handling'
      }

      if (auto4xxErrors) {
        // Intercept HTTP status 400 range errors and raise the page region error banner
        if (error.statusCode >= 400 && error.statusCode <= 499) {
          raisePageRegionError('useServerErrors', error, state)
          throw 'skip-additional-error-handling'
        }
      }

      if (auto5xxErrors) {
        // Intercept HTTP status 500 range errors and raise the page region error banner
        if (error.statusCode >= 500 && error.statusCode <= 599) {
          raisePageRegionError('useServerErrors', error, state)
          throw 'skip-additional-error-handling'
        }
      }
      throw error
    })
    .finally(() => {
      state.loading = false
    })
}
export default { raisePageRegionError, performFetch }
