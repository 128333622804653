import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list_item_group = _resolveComponent("v-list-item-group")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    app: "",
    "model-value": _ctx.modelValue,
    permanent: !_ctx.navType,
    temporary: _ctx.navType
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_divider),
      _createVNode(_component_v_list, {
        dense: "",
        nav: "",
        subheader: "",
        class: "pa-0"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navList, (item) => {
            return (_openBlock(), _createBlock(_component_v_list_item_group, {
              key: item.title
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_group, {
                  color: "primary",
                  mandatory: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item, {
                      to: { name: item.routeName },
                      color: "primary",
                      rounded: "0",
                      onClick: _ctx.itemClicked
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_content, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "pl-2" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.title), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["to", "onClick"])
                  ]),
                  _: 2
                }, 1024),
                (item.divider)
                  ? (_openBlock(), _createBlock(_component_v_divider, { key: 0 }))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value", "permanent", "temporary"]))
}