/**
 * Lazyload Vue page from the 'pages' root directory
 * @param page: string - Name of Vue page to load
 * @returns lazy loaded vue page
 */
export function lazyload(page: string) {
  return () => import(`@/pages/${page}.vue`)
}

/**
 * Convert a Stringified number to a number with commas
 * @param value: string or number - Number to format
 * @returns stringified number with comma in the thousands place
 */
export function formatNumberWithCommas(value: number | string): string {
  const valueWithoutCommas = String(value).replace(/\D/g, '')
  return new Intl.NumberFormat().format(Number(valueWithoutCommas))
}

/**
 * Sort an array of objects based on a key within each object.
 * @param arr array that will be sorted in place - should be an array of objects.
 * @param key key in each object that will determine that objects sort order - as long as the array passed in is well typed then we should get intellisense here.
 * @param direction direction of the sorting
 */
export function sortByKey<T = { [key: string]: any }>(arr: T[], key: keyof T, direction?: 'asc' | 'desc') {
  arr.sort((a, b) => {
    const order = (direction ?? 'asc') ? a[key] > b[key] : a[key] < b[key]

    return order ? 1 : -1
  })
  return arr
}

export default { lazyload, formatNumberWithCommas }
