const routes = [
  {
    path: '/contacts',
    redirect: { name: 'ContactsSearch' },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('./pages/ContactBase.vue'),
    meta: {
      pageTitle: 'Contacts',
      requiresAuth: true,
      resourceName: 'CNTR',
      grant: 'READ',
    },
    children: [
      {
        name: 'ContactsSearch',
        path: 'search',
        component: () => import('./pages/ContactsSearch.vue'),
      },
      {
        name: 'AddContact',
        path: 'add',
        component: () => import('./pages/CreateContact.vue'),
        meta: {
          grant: 'WRITE',
        },
      },
      {
        name: 'EditContact',
        path: ':id/edit',
        component: () => import('./pages/EditContact.vue'),
        meta: {
          grant: 'WRITE',
        },
      },
      {
        name: 'ContactsRegions',
        path: 'regions',
        component: () => import('./pages/ContactsRegions.vue'),
      },
      {
        name: 'ContactsAreas',
        path: 'areas',
        component: () => import('./pages/ContactsAreas.vue'),
      },
    ],
  },
]

export default routes
