const routes = [
  {
    name: 'AccessBase',
    path: '/access',
    component: () => import('./pages/AccessBase.vue'),
    meta: {
      pageTitle: 'AccessBase',
      requiresAuth: true,
      resourceName: 'USER-EMPL',
    },
    redirect: { name: 'AccessUsers' },
    children: [
      {
        name: 'AccessUsers',
        path: 'users',
        component: () => import('./pages/AccessUsers.vue'),
      },
      {
        name: 'UserEdit',
        path: 'edit/:id',
        component: () => import('./pages/AccessUserEdit.vue'),
      },
      {
        name: 'AddUser',
        path: 'users/add',
        component: () => import('./pages/AccessUserAdd.vue'),
      },
      {
        name: 'AccessGroups',
        path: 'groups',
        component: () => import('./pages/AccessGroupList.vue'),
      },
      {
        name: 'EditGroup',
        path: 'groups/edit/:groupId',
        component: () => import('./pages/AccessGroupEdit.vue'),
        props: true,
      },
      {
        name: 'AddGroup',
        path: 'groups/add',
        component: () => import('./pages/AccessGroupAdd.vue'),
        props: true,
      },
    ],
  },
]

export default routes
