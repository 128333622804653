const messages = {
    en: {
      message: {
        hello: 'hello world'
      },
      pagination: {
        itemsPerPage: "Items per page"
      }
    },
    ja: {
      message: {
        hello: 'こんにちは、世界'
      }
    }
  }
  
 export default messages
