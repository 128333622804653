import useCheckTokenExpiry from './checkTokenExpiry'
import refreshToken from './refreshToken'
import identityHandling from './identityHandling'

/**
 * setupHeaders
 * Before setting headers - check token expiry, if needed, attempt token refresh
 */
const setupHeaders = async (additionalHeaders: any): Promise<Headers> => {
  const tokenExpired = useCheckTokenExpiry()
  if (tokenExpired) await refreshToken()

  let headers = new Headers()
  headers = identityHandling(headers) || headers
  headers.append('Content-Type', 'application/json')
  if (additionalHeaders) {
    for (const i in additionalHeaders) {
      headers.append(i, additionalHeaders[i])
    }
  }
  return headers
}

export default setupHeaders
