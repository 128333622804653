const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
  return JSON.parse(jsonPayload)
}

export const getUserName = () => {
  const identityToken: string | null = localStorage.getItem('identityToken')
  if (!identityToken) return

  const userInfo = parseJwt(identityToken)
  const { given_name, family_name } = userInfo
  return { given_name, family_name }
}

export const getResources = () => {
  const identityToken: string | null = localStorage.getItem('identityToken')
  if (!identityToken) return

  const userInfo = parseJwt(identityToken)
  let { resources } = userInfo
  resources += ',ALWAYS_AVAILABLE'
  return resources
}

export const getUserEmail = () => {
  const identityToken: string | null = localStorage.getItem('identityToken')
  if (!identityToken) return

  const userInfo = parseJwt(identityToken)
  return userInfo.email
}

export const getUserExp = (): number | null => {
  const identityToken: string | null = localStorage.getItem('identityToken')
  if (!identityToken) return null

  const userInfo = parseJwt(identityToken)
  return userInfo.exp
}
