const routes = [
  {
    name: 'Loyalty',
    path: '/loyalty',
    component: () => import('./pages/LoyaltyBase.vue'),
    meta: {
      pageTitle: 'Loyalty',
      requiresAuth: true,
      resourceName: 'LOYALTY',
    },
    redirect: { name: 'LoyaltyTiers' },
    children: [
      {
        name: 'LoyaltyTiers',
        path: 'tiers',
        component: () => import('./pages/LoyaltyTiers.vue'),
      },
      {
        name: 'LoyaltyTiersEdit',
        path: 'tiers-edit/:loyaltyTierId',
        component: () => import('./pages/LoyaltyTiersEdit.vue'),
      },
      {
        name: 'LoyaltyPromotions',
        path: 'promotions',
        component: () => import('./pages/LoyaltyPromotions.vue'),
      },
    ],
  },
]

export default routes
