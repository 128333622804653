const routes = [
  {
    path: '/dvm',
    redirect: { name: 'DVMHome' },
  },
  {
    path: '/dvm',
    name: 'DVM',
    component: () => import('@/pages/DVM.vue'),
    meta: {
      pageTitle: 'DVM',
      requiresAuth: true,
      resourceName: 'DVM-RPT',
    },
    children: [
      {
        name: 'DVMHome',
        path: 'home',
        component: () => import('./pages/DVMHome.vue'),
      },
      {
        name: 'masterClassItems',
        path: 'masterClassItems',
        component: () => import('./pages/ItemMasterClass.vue'),
      },
      {
        name: 'masterClassAdd',
        path: 'masterClassItemsAdd',
        component: () => import('./pages/MasterClassItemsAdd.vue'),
      },
      {
        name: 'masterClassEdit',
        path: 'masterClassItemsEdit/:id',
        component: () => import('./pages/MasterClassItemsEdit.vue'),
      },
      {
        name: 'DVMAdd',
        path: 'add',
        component: () => import('./pages/DVMAdd.vue'),
        meta: {
          grant: 'WRITE',
        },
      },
      {
        name: 'DVMEdit',
        path: 'edit',
        component: () => import('./pages/DVMEdit.vue'),
        meta: {
          grant: 'WRITE',
        },
      },
      {
        name: 'DVMBonus',
        path: 'bonus/:id',
        component: () => import('./pages/DVMBonus.vue'),
        meta: {
          grant: 'WRITE',
        },
      },
    ],
  },
]

export default routes
