import router from '@/router'

const identityHandling = function (headers: Headers, skipRouting: boolean = false) {
  const identityToken: string | null = localStorage.getItem('identityToken')
  if (!identityToken) {
    if (!skipRouting) router.push({ name: 'Login' })
    return
  }
  headers.append('Authorization', identityToken)
  return headers
}

export default identityHandling
