import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_header_text = _resolveComponent("v-card-header-text")!
  const _component_v_card_header = _resolveComponent("v-card-header")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.props.visible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.props.visible) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { class: "mx-auto" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_header, { class: "bg-primary py-3 px-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_header_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.title), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, { class: "px-4" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.props.text), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, { class: "d-flex justify-center mb-3" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "secondary",
                  variant: "outlined",
                  onClick: _ctx.handleCancel
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.cancelTitle), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_btn, {
                  color: "primary",
                  onClick: _ctx.handleContinue
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.continueTitle), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}