
import { defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Appbar',
  props: {
    userName: String,
  },
  emits: ['toggleNav', 'openNav'],
  setup(props, { emit }) {
    const router = useRouter()

    function toggleNav(): void {
      this.$emit('toggleNav')
    }

    const handleMenuClick = (value: boolean) => {
      state.open = value
    }

    const handleLogout = () => {
      localStorage.removeItem('identityToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('resources')
      state.open = false
      emit('openNav')
      router.push('/login')
    }

    const state = reactive({
      items: [
        { title: 'Logout', action: handleLogout },
      ],
      open: false,
    })

    return { state, toggleNav, handleLogout, handleMenuClick }
  },
})
