import { getUserExp } from '@/composables/extractFromJwt'
import { useInactivityTimer } from '@/composables/useInactivityTimer'

const useCheckTokenExpiry = (): Boolean => {
  return shouldRefreshToken()
}
const { setLastActivity } = useInactivityTimer()
/**
 * shouldRefreshToken
 *
 * Compare token expiry to current time
 * If within 3 minutes of expiry or after; request new token
 * @returns Boolean
 */
const shouldRefreshToken = (): Boolean => {
  const currentDate: Date = new Date()
  const current: number = currentDate.getTime() / 1000
  const tokenExp: number | null = getUserExp()
  setLastActivity()

  if (!tokenExp) return false
  const remaining: number = tokenExp - current
  if (remaining >= 3) return false
  return true
}

export default useCheckTokenExpiry
