const routes = [
  {
    path: '/center-cash-reporting',
    redirect: { name: 'DailyEntry' },
    meta: {
      resourceName: 'CNTR-AR',
    },
  },
  {
    path: '/center-cash-reporting',
    name: 'DailyDestPet',
    component: () => import('@/pages/DailyDestPet.vue'),
    meta: {
      pageTitle: 'Center Cash Reporting',
      requiresAuth: true,
      resourceName: 'CNTR-AR',
    },
    children: [
      {
        name: 'DailyEntry',
        path: 'daily-entry/:center?',
        component: () => import('./pages/DailyEntry.vue'),
      },
      {
        name: 'DailyDestList',
        path: 'list/:center?',
        component: () => import('./pages/DailyDestPetList.vue'),
      },
      {
        name: 'DailyEntryEdit',
        path: 'edit/:date/:center',
        component: () => import('./pages/DailyEntryEdit.vue'),
      },
    ],
  },
]

export default routes
