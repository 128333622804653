export default function filterList(list: Array<Object>, resources: string | null) {
  if (!resources) return []
  const resourceList = resources.split(',')

  return list.reduce((acc: any, item: any) => {
    const match = resourceList.indexOf(item.resourceName)
    if (match !== -1) acc.push(item)
    return acc
  }, []) 
}
