/**
 * handleError
 *
 * Handle all error responses that are not in the 200 HTTP status range by
 * throwing error - this error gets handled by the fetch catch block below
 * For 200 HTTP status range responses; return response in JSON format
 */
  const handleError = async (res: Response) => {
  if (!res.ok) {
    const resToRead = res.clone()
    const errorBody = await resToRead.json()
    const combined = Object.assign(errorBody, { statusCode: res.status })
    throw combined
  } else {
    return res.json()
  }
}

export default handleError
