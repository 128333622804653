
import { defineComponent, onMounted, reactive,ComponentPublicInstance,ref } from 'vue'
import UserService from '@/services/UserService.vue'
import { getResources } from '@/composables/extractFromJwt'
import router from '@/router'
import { useInactivityTimer } from '@/composables/useInactivityTimer'

export default defineComponent({
  name: 'SsoLogin',
  components: {
    UserService,
  },
  setup() {
    const userServiceRef = ref<ComponentPublicInstance<typeof UserService> | null>(null)
    let fnUserServiceValidateSso: Function
    let fnUserSsoLogin:Function

    const state = reactive({
      showPassword: false,
      loading: false,
      status: '',
      error: '',
    })

    onMounted(() => {
      fnUserServiceValidateSso = userServiceRef.value?.checkUserSso
      fnUserSsoLogin = userServiceRef.value?.ssoLogin
      if (router.currentRoute.value.query.code) {
        userSsoLogin()
      }
    })

    const setUserResources = (): void => {
      let resources = getResources()
      if (resources) {
        localStorage.setItem('resources', resources)
        router.push({ name: 'Dashboard' })
      } else {
        localStorage.removeItem('resources')
        router.push('no-access')
      }
      useInactivityTimer()
    }

    const handleLoggedIn = () => {
      setUserResources()
    }
 
    const validateUserSso = () => {
      try{
        state.loading = true
        const setup = {
          method: 'POST',
        }
        fnUserServiceValidateSso(setup).then((res: any) => {
          window.location.href = res.data.link
        }).catch((error: any) => {
          if (error !== 'skip-additional-error-handling') {
            console.error(error)
          }
        })
      }catch(error){
        state.loading = false
        if (error !== 'skip-additional-error-handling') {
            console.error(error)

          }
      }
    }

    const userSsoLogin = () => {
        state.loading = true
        const code = router.currentRoute.value.query.code
        const setup = {
          method: 'POST',
          body: JSON.stringify({ code }),
        }
        fnUserSsoLogin(setup).then((res: any)=>{
          const { identityToken, refreshToken} = res
          localStorage.setItem('identityToken', identityToken)
          localStorage.setItem('refreshToken', refreshToken)

          handleLoggedIn()
        }).catch((error: any) => {
          state.loading = false
          if (error !== 'skip-additional-error-handling') {
            console.error(error)
          }
        })
    }

    return { state,  handleLoggedIn,userServiceRef,validateUserSso,userSsoLogin }
  },
})
