<template>
  <confirmation-dialog
    :visible="showIdleWarning"
    title="Idle Warning"
    text="You are about to be logged out due to inactivity"
    cancelTitle="Close"
    continueTitle="Stay Logged In"
    @cancelClicked="close"
    @continueClicked="stayLoggedIn"
  ></confirmation-dialog>
</template>
<script>
import { defineComponent } from 'vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import { useInactivityTimer } from '@/composables/useInactivityTimer'

export default defineComponent({
  components: { ConfirmationDialog },
  setup() {
    const { showIdleWarning, setLastActivity } = useInactivityTimer()
    const close = () => {
      showIdleWarning.value = false
    }
    const stayLoggedIn = () => {
      showIdleWarning.value = false
      setLastActivity()
    }
    return {
      close,
      stayLoggedIn,
      showIdleWarning,
    }
  },
})
</script>
