import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_app_bar, {
      density: "compact",
      color: "primary",
      fixed: "",
      elevation: "5"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_nav_icon, {
          size: "small",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleNav())),
          class: "ml-1"
        }),
        _createVNode(_component_v_app_bar_title, { class: "text-h5" }, {
          default: _withCtx(() => [
            _createTextVNode("Destination Pet Admin")
          ]),
          _: 1
        }),
        _createVNode(_component_v_spacer),
        _createElementVNode("span", null, _toDisplayString(_ctx.userName), 1),
        _createVNode(_component_v_menu, {
          "onUpdate:modelValue": _ctx.handleMenuClick,
          "model-value": _ctx.state.open
        }, {
          activator: _withCtx(({ props }) => [
            _createVNode(_component_v_btn, _mergeProps({
              icon: "",
              size: "small"
            }, props, {
              "aria-expanded": "false",
              class: "pl-3"
            }), {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-dots-vertical")
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1040)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_list, { class: "bg-primary" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.items, (item, index) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: index,
                    value: index,
                    onClick: item.action
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.title), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["value", "onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onUpdate:modelValue", "model-value"])
      ]),
      _: 1
    })
  ]))
}