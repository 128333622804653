<template>
  <v-container class="row">
    <v-row class="row justify-center align-center">
      <v-col>
        <v-row>
          <v-col>
            <h1 class="text-h4 font-weight-regular mb-2 text-grey-darken-2 heading text-center">
              An error occurred trying to load the page
            </h1>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex justify-center">
            <router-link :to="{ name: 'Dashboard' }" class="text-no-wrap mr-4">Home</router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.row {
  height: 100%;
}
.heading {
  padding: 20px;
}
</style>
