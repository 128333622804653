import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.state.showAlert)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#alert-region"
        }, [
          _createVNode(_component_v_alert, {
            density: "compact",
            variant: "outlined",
            type: "error",
            class: "ma-2",
            prominent: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.state.alertMessage), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_overlay, {
      "model-value": _ctx.state.loading,
      class: "loading"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_progress_circular, {
          indeterminate: "true",
          size: "64",
          color: "primary"
        })
      ]),
      _: 1
    }, 8, ["model-value"])
  ], 64))
}