const addParams = (url: URL, params: any) => {
  if (params) {
    for (const i in params) {
      url.searchParams.append(i, params[i])
    }
  }
  return url
}

export default addParams
