import { ref } from 'vue'
import { app } from '@/main'

/** maximum idle time before user is logged out (in minutes) */
const MAX_IDLE_TIME = 120
/** warn user of logout X minutes before it happens (in minutes) */
let WARN_IDLE_TIME = 5
/** interval to check idle time (in seconds) */
let CHECK_IDLE_TIME = 30
/** whether or not the idle warning has been triggered */
let idleWarned: boolean = false
/** whether or not we are actively showing the idle warning */
const showIdleWarning = ref<boolean>(false)
/** reference to the setInterval instance */
let watcher: any = -1

export const useInactivityTimer = (): any => {
  /**
   * sets lastActivity in localStorage to the current date (in millis)
   * this is called by every api call (via checkTokenExpiry::shouldRefreshToken)
   */
  const setLastActivity = (): void => {
    const currentDate = new Date()
    localStorage.setItem('lastActivity', currentDate.getTime().toString())
    idleWarned = false
  }

  /**
   * gets the lastActivity from localStorage
   * call setLastActivity if we get null from localStorage
   * @returns the last activity (Date in millis)
   */
  const getLastActivity = (): number => {
    let lastActivity = localStorage.getItem('lastActivity')
    if (lastActivity === null) {
      setLastActivity()
      lastActivity = localStorage.getItem('lastActivity')
    }
    return Number(lastActivity)
  }

  /**
   * Checks to see if the idle warning should be displayed
   * @returns true, if the idle warning should be displayed
   */
  const isTimeForIdleWarning = (): boolean => {
    const lastActivity = getLastActivity()
    let idleTimeWarning = MAX_IDLE_TIME - WARN_IDLE_TIME
    if (idleTimeWarning < 0) {
      // we have less than WARN_IDLE_TIME minutes remaining
      // effectively, do not show the idle timeout warning
      idleTimeWarning = 0
      idleWarned = true
    }
    const lastActivityAddIdle = lastActivity + idleTimeWarning * 60 * 1000
    return new Date().getTime() >= lastActivityAddIdle
  }
  /**
   * Checks to see if the idle time has exceeded the max
   * @returns true, if the idle time has exceeded the max
   */
  const isIdleTimeOverThreshold = (): boolean => {
    const lastActivity = getLastActivity()
    const lastActivityAddIdle = lastActivity + MAX_IDLE_TIME * 60 * 1000
    return new Date().getTime() >= lastActivityAddIdle
  }
  /**
   * logs the user out and redirects to the login page
   */
  const handleLogout = (): void => {
    if (watcher > -1) {
      clearInterval(watcher)
      watcher = -1
    }
    localStorage.removeItem('resources')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('identityToken')
    localStorage.removeItem('lastActivity')
    document.removeEventListener('freeze', () => {})
    document.removeEventListener('resume', () => {})
    console.info('User was logged out.')
    app.$router.push('/login')
  }
  /**
   * Determine whether the user is logged in or not
   * @returns true, if the user is logged in
   */
  const isLoggedIn = (): boolean => {
    const resources = localStorage.getItem('resources')
    const rt = localStorage.getItem('refreshToken')
    const id = localStorage.getItem('identityToken')
    return resources !== null && rt !== null && id !== null
  }
  /**
   * Watches the idle time and triggers the logout or warning when required
   */
  const watchIdleActivity = (): void => {
    if (!isLoggedIn()) {
      handleLogout()
    }
    if (isIdleTimeOverThreshold()) {
      idleWarned = true
      showIdleWarning.value = false
      handleLogout()
    } else if (isTimeForIdleWarning() && !idleWarned) {
      idleWarned = true
      showIdleWarning.value = true
    }
  }
  /**
   * starts the watcher interval
   * triggers the watchIdleActivity method every CHECK_IDLE_TIME seconds
   */
  const startWatcher = () => {
    if (watcher === -1) {
      if (MAX_IDLE_TIME <= 0) {
        console.warn(`useInactivityTimer> MAX_IDLE_TIME is ${MAX_IDLE_TIME}. Watcher is disabled`)
        return
      }
      if (WARN_IDLE_TIME <= 0) {
        console.warn(`useInactivityTimer> WARN_IDLE_TIME is ${WARN_IDLE_TIME} (invalid). Defaulting to 5 minutes`)
        WARN_IDLE_TIME = 5
      }
      if (CHECK_IDLE_TIME <= 0) {
        console.warn(`useInactivityTimer> CHECK_IDLE_TIME is ${CHECK_IDLE_TIME} (invalid). Defaulting to 30 seconds`)
        CHECK_IDLE_TIME = 30
      }
      document.addEventListener('freeze', () => {})
      document.addEventListener('resume', (event: any) => watchIdleActivity())
      watcher = setInterval(watchIdleActivity, CHECK_IDLE_TIME * 1000)
      window.onbeforeunload = () => {
        document.removeEventListener('freeze', () => {})
        document.removeEventListener('resume', () => {})
      }
    }
  }
  /**
   * start the watcher only if the user is logged in
   */
  if (isLoggedIn()) {
    startWatcher()
  }
  return {
    setLastActivity,
    handleLogout,
    showIdleWarning,
  }
}
