const routes = [
  {
    path: '/center',
    redirect: { name: 'CenterBase' },
  },
  {
    name: 'CenterBase',
    path: '/center/:id',
    component: () => import('./pages/CenterBase.vue'),
    meta: {
      pageTitle: 'Center',
      requiresAuth: true,
      resourceName: 'CNTR',
    },
    children: [
      {
        name: 'CenterMain',
        path: 'center-main',
        component: () => import('./pages/CenterMain.vue'),
      },
      {
        name: 'CenterMainEdit',
        path: 'center-main/edit',
        component: () => import('./pages/CenterMainEdit.vue'),
        meta: {
          grant: 'WRITE',
        },
      },
      {
        name: 'CenterMarketing',
        path: 'marketing',
        component: () => import('./pages/CenterMarketing.vue'),
        meta: {
          resourceName: 'CNTR-MRKT',
        },
      },
      {
        name: 'CenterMarketingEdit',
        path: 'marketing/edit',
        component: () => import('./pages/CenterMarketingEdit.vue'),
        meta: {
          resourceName: 'CNTR-MRKT',
          grant: 'WRITE',
        },
      },
      {
        name: 'CenterMarketingEditYougri',
        path: 'marketing/edit/:service',
        component: () => import('./pages/CenterMarketingEditYourgi.vue'),
        meta: {
          resourceName: 'CNTR-MRKT',
        },
      },
      {
        name: 'CenterOperations',
        path: 'operations',
        component: () => import('./pages/CenterOperations.vue'),
        meta: {
          resourceName: 'CNTR-OPER',
        },
      },
      {
        name: 'CenterServices',
        path: 'services',
        component: () => import('./pages/CenterServices.vue'),
      },
      {
        name: 'CenterServicesEdit',
        path: 'services/edit/:service',
        component: () => import('./pages/CenterServicesEdit.vue'),
        meta: {
          grant: 'WRITE',
        },
      },
      {
        name: 'CenterOperationsEdit',
        path: 'operations/edit',
        component: () => import('./pages/CenterOperationsEdit.vue'),
        meta: {
          resourceName: 'CNTR-OPER',
          grant: 'WRITE',
        },
      },
      {
        name: 'CenterLocation',
        path: 'location',
        component: () => import('./pages/CenterLocation.vue'),
      },
      {
        name: 'CenterLocationEdit',
        path: 'location/edit',
        component: () => import('./pages/CenterLocationEdit.vue'),
        meta: {
          grant: 'WRITE',
        },
      },
      {
        name: 'CenterFiles',
        path: 'center-files',
        component: () => import('./pages/CenterFiles.vue'),
      },
      {
        name: 'CenterFilesAdd',
        path: 'center-files/upload',
        component: () => import('./pages/CenterFilesAdd.vue'),
      },
      {
        name: 'CenterDataSources',
        path: 'data-source',
        component: () => import('./pages/CenterDataSources.vue'),
        meta: {
          resourceName: 'CNTR-PIMS',
        },
      },
      {
        name: 'CenterDatasourceEdit',
        path: 'data-source/:dataSourceId/edit',
        component: () => import('./pages/CenterDataSourcesEdit.vue'),
        meta: {
          resourceName: 'CNTR-PIMS',
          grant: 'WRITE',
        },
      },
      {
        name: 'CenterDatasourceAdd',
        path: 'data-source/add',
        component: () => import('./pages/CenterDatasourceAdd.vue'),
        meta: {
          resourceName: 'CNTR-PIMS',
          grant: 'WRITE',
        },
      },
      {
        name: 'CenterContacts',
        path: 'contacts',
        component: () => import('./pages/CenterContacts.vue'),
      },
      {
        name: 'CenterContactsAdd',
        path: 'contacts/add',
        component: () => import('./pages/CenterContactsAdd.vue'),
        meta: {
          grant: 'WRITE',
        },
      },
      {
        name: 'CenterNotes',
        path: 'center-notes',
        component: () => import('./pages/CenterNotes.vue'),
        meta: {
          resourceName: 'CNTR-NOTES',
        },
      },
      {
        name: 'CenterITNotes',
        path: 'center-it-notes',
        component: () => import('./pages/CenterITNotes.vue'),
        meta: {
          resourceName: 'CNTR-ITNOTES',
        },
      },

      {
        name: 'CenterImages',
        path: 'center-images',
        component: () => import('./pages/CenterImages.vue'),
      },
      {
        name: 'CenterAddImage',
        path: 'center-images/add',
        component: () => import('./pages/CenterImagesAdd.vue'),
      },
      {
        name: 'CenterPreboardingCheckList',
        path: 'pre-boarding-checklist',
        component: () => import('./pages/CenterPreboardingCheckList.vue'),
      },
      {
        name: 'CenterPreboardingAddQuestion',
        path: 'pre-boarding-checklist/add-question/:sectionId',
        component: () => import('./pages/CenterPreboardingAddQuestion.vue'),
        meta: {
          grant: 'WRITE',
        },
      },
      {
        name: 'CenterPreboardingEditQuestion',
        path: 'pre-boarding-checklist/edit-question/:questionId',
        component: () => import('./pages/CenterPreboardingEditQuestion.vue'),
        meta: {
          grant: 'WRITE',
        },
      },
      {
        name: 'CenterPreboardingSortSectionQuestions',
        path: 'pre-boarding-checklist/sort-section/:sectionId',
        component: () => import('./pages/CenterPreboardingSortSectionQuestions.vue'),
        meta: {
          grant: 'WRITE',
        },
      },
      {
        name: 'CenterCameras',
        path: 'center-cameras',
        component: () => import('./pages/CenterCameras.vue'),
      },
      {
        name: 'CenterCamerasEdit',
        path: 'center-cameras/edit',
        component: () => import('./pages/CenterCamerasEdit.vue'),
      },
      {
        name: 'CenterITVendors',
        path: 'center-it-vendors',
        component: () => import('./pages/CenterITVendors.vue'),
      },
      {
        name: 'CenterITVendorsAdd',
        path: 'center-it-vendors/add',
        component: () => import('./pages/CenterITVendorsAdd.vue'),
        meta: {
          resourceName: 'CNTR-ITNOTES',
          grant: 'WRITE',
        },
      },
      {
        name: 'CenterITVendorsEdit',
        path: 'center-it-vendors/:vendorId/edit',
        component: () => import('./pages/CenterITVendorsEdit.vue'),
        meta: {
          resourceName: 'CNTR-ITNOTES',
          grant: 'WRITE',
        },
      },
    ],
  },
]

export default routes
