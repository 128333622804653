const routes = [
  {
    path: '/centers',
    redirect: { name: 'CentersList' },
  },
  {
    path: '/centers',
    name: 'Centers',
    component: () => import('@/pages/Centers.vue'),
    meta: {
      pageTitle: 'Centers',
      requiresAuth: true,
      resourceName: 'CNTR',
    },
    children: [
      {
        name: 'CentersList',
        path: 'list',
        component: () => import('./pages/CentersList.vue'),
      },
      {
        name: 'CenterAdd',
        path: 'add',
        component: () => import('./pages/CenterAdd.vue'),
        meta: {
          grant: 'WRITE',
        },
      },
    ],
  },
]

export default routes
