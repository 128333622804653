import { createRouter, createWebHistory } from 'vue-router'
import routesDailyDestPet from '@/modules/DailyDestPet/routes'
import routesCenters from '@/modules/Centers/routes'
import routesCenter from '@/modules/Center/routes'
import routesYourgiServices from '@/modules/YourgiServices/routes'
import routesDVM from '@/modules/DVM/routes'
import routesContacts from '@/modules/Contacts/routes'
import routesOperations from '@/modules/Operations/routes'
import routesAccessManagement from '@/modules/AccessManagement/routes'
import routesConnectedCareData from '@/modules/ConnectedCare/routes'
import routesCenterOnboarding from '@/modules/CenterOnboarding/routes'
import routesLoyalty from '@/modules/Loyalty/routes'
import { lazyload } from '@/util/helpers'
import checkGrant, { getEmpGrants } from '@/composables/grants'
import Login from '@/components/Login.vue'
import ErrorPage from '@/pages/Error.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: lazyload('DailyDestPet'),
    meta: {
      pageTitle: 'Center Cash Reporting',
      requiresAuth: true,
      resourceName: 'CNTR-AR',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      pageTitle: 'Login',
      resourceName: 'ALWAYS_AVAILABLE',
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: lazyload('Home'),
    meta: {
      pageTitle: 'Dashboard',
      requiresAuth: true,
      resourceName: 'ALWAYS_AVAILABLE',
    },
  },
  {
    path: '/no-access',
    name: 'NoAccess',
    component: lazyload('NoAccess'),
    meta: {
      pageTitle: 'No Access',
      requiresAuth: true,
      resourceName: 'ALWAYS_AVAILABLE',
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage,
    meta: {
      pageTitle: 'Error',
      requiresAuth: true,
      resourceName: 'ALWAYS_AVAILABLE',
    },
  },
  {
    // path: "*",
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: lazyload('404'),
    meta: {
      requiresAuth: false,
    },
  },
]

const combinedRoutes = [
  ...routes,
  ...routesDailyDestPet,
  ...routesCenters,
  ...routesCenter,
  ...routesYourgiServices,
  ...routesContacts,
  ...routesOperations,
  ...routesAccessManagement,
  ...routesConnectedCareData,
  ...routesDVM,
  ...routesCenterOnboarding,
  ...routesLoyalty,
]
const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes: combinedRoutes,
})

/**
 * Check if route requires Auth and if a identityToken is present
 */

router.beforeEach((to, from, next) => { 
  const identityTokenExists = !!localStorage.getItem('identityToken')
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (requiresAuth && !identityTokenExists) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

router.beforeEach(async (to, from, next) => {
  const resourceName = to.meta.resourceName as string
  const resourceExist = checkResource(resourceName)
  let grantExist
  if (resourceName && to.meta.grant) {
    await getEmpGrants()
    grantExist = checkGrant(resourceName, to.meta.grant as string)
  }
  if ((!resourceExist && resourceName !== 'ALWAYS_AVAILABLE') || grantExist?.value === false) {
    next('/no-access')
  } else {
    next()
  }
})

/* 
  Solution to navigate to different routes with different params
  during vuetify upgrade to ^3.0.0, the following error was occurring:
  "Error: Missing required param 'id'"
*/ 
router.beforeEach((to, from, next) => {
  to.params = { ...from.params, ...to.params }
  next()
})

router.onError((error: Error) => {
  if (error.name === 'ChunkLoadError') {
    router.push('/error')
  }
})
const checkResource = (resourceName: any): boolean | undefined => {
  const resources: string | null = localStorage.getItem('resources')
  return resources?.includes(resourceName)
}

export default router
