const routes = [
  {
    path: '/center-onboarding',
    component: () => import('./pages/CenterOnboardingBase.vue'),
  },
  {
    name: 'CenterOnboardingBase',
    path: '/center-onboarding',
    component: () => import('./pages/CenterOnboardingBase.vue'),
    meta: {
      pageTitle: 'Center Onboarding',
      requiresAuth: true,
      resourceName: 'CNTR-ONBD',
    },
    children: [
      {
        name: 'CenterOnboardingList',
        path: 'list',
        component: () => import('./pages/CenterOnboardingList.vue'),
      },
      {
        name: 'CenterOnboardingHistory',
        path: 'history',
        component: () => import('./pages/CenterOnboardingHistory.vue'),
      },
      {
        name: 'CenterOnboardingStages',
        path: 'stages',
        component: () => import('./pages/CenterOnboardingStages.vue'),
      },
      {
        name: 'CenterOnboardingAdd',
        path: 'add',
        component: () => import('./pages/CenterOnboardingAdd.vue'),
      },
      {
        name: 'CenterOnboardingEdit',
        path: 'update/:id',
        component: () => import('./pages/CenterOnboardingEdit.vue'),
      },
    ],
  },
]

export default routes
