const routes = [
  {
    path: '/connected-care',
    redirect: { name: 'CustomerSearch' },
  },
  {
    name: 'ConnectedCareBase',
    path: '/connected-care',
    component: () => import('./pages/ConnectedCareBase.vue'),
    meta: {
      pageTitle: 'ConnectedCareBase',
      requiresAuth: true,
      resourceName: 'CRM-CUSTDB',
    },
    children: [
      {
        name: 'CustomerSearch',
        path: 'customer-search',
        component: () => import('./pages/CustomerSearch.vue'),
      },
      {
        name: 'CustomerBase',
        path: 'customer/:id',
        redirect: { name: 'CustomerDetails' },
        component: () => import('./pages/CustomerBase.vue'),
        children: [
          {
            name: 'CustomerDetails',
            path: 'details',
            component: () => import('./pages/CustomerDetails.vue'),
          },
          {
            name: 'UserExperience',
            path: 'user-experience',
            component: () => import('./pages/UserExperience.vue'),
          },
          {
            name: 'UserLoyalty',
            path: 'user-loyalty',
            component: () => import('./pages/Loyalty.vue'),
          },
          {
            name: 'UserLoyaltyEdit',
            path: 'user-loyalty-edit',
            component: () => import('./pages/LoyaltyEdit.vue'),
          },
          {
            name: 'CustomerDetailsEdit',
            path: 'edit',
            component: () => import('./pages/CustomerDetailsEdit.vue'),
          },
          {
            name: 'GlobalPetDetailsEdit',
            path: 'pet/:petId/edit', // Changed path to include pet/:id
            component: () => import('./pages/GlobalPetDetailsEdit.vue'),
          },
        ],
      },
    ],
  },
]

export default routes
