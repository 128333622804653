/**
 * v-currency:arg1:arg2="value"
 * 
 * Directive for formatting numbers based on locale and specified currency
 *  
 * Wrapper around built in Intl.NumberFormat
 * 
 * value and args:
 *   value: number to convert and output in element
 *   arg position 1: currency - [optional] 
 *   arg position 2: locale   - [optional] 
 * 
 * Examples:
 *   const amount = "300"
 *   When specifing both args:
 *     <p v-currency:USD:us-EN="amount"></p>
 *     output: $300.00
 *   When only specifing the first arg
 *     <p v-currency:USD="amount"></p>
 *     output: $300.00
 *     same as:
 *     <p v-currency:USD:default="amount"></p>
 *     output: $300.00
 *   When only specifing the second arg
 *     <p v-currency:default:us-EN="amount"></p>
 *     output: $300.00
 *   When not specifing any args. Uses default args
 *     <p v-currency="amount"></p>
 *     output: $300.00
 * 
 *   Note: Uses the shorthand callback signature for the directive, runs the callback for 'mounted' and 'updated' hooks
 */

export const dpCurrency: any = (el: any, binding: any) => {
  const args = binding.arg ? binding.arg.split(':') : []

  let currencyFormat = args[0] || 'USD'
  currencyFormat = currencyFormat === 'default' ? 'USD' : currencyFormat

  let locale = args[1] || 'us-EN'
  locale = locale === 'default' ? 'us-EN' : locale

  const startingTextContent = binding.value
  if (typeof startingTextContent !== 'number') {
    console.error(`v-dp-currency attribute requires a number. typeof ${startingTextContent} is ${typeof startingTextContent}`)
    return ''
  }
  el.textContent = dpCurrencyCore(startingTextContent, currencyFormat, locale)
}

export const dpCurrencyCore: any = (startingValue: number, currencyValue: string, localeValue: string): string => {
  let currencyFormat = currencyValue || 'USD'
  currencyFormat = currencyFormat === 'default' ? 'USD' : currencyFormat

  let locale = localeValue || 'us-EN'
  locale = locale === 'default' ? 'us-EN' : locale

  return new Intl.NumberFormat(locale, { style: 'currency', currency: currencyFormat }).format(startingValue)
}

export default { dpCurrency, dpCurrencyCore }
