import { createApp } from 'vue'
import App from './App.vue'
import router from '../src/router'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import toast, { toastConfig } from './plugins/toast'
import { loadFonts } from './plugins/webfontloader'
import { dpCurrency } from './directives/currency'
import './registerServiceWorker.ts'

loadFonts()

export const app = createApp(App)
  .use(router)
  .use(vuetify)
  .use(i18n)
  .use(toast, toastConfig)
  .directive('dp-currency', dpCurrency)
  .mount('#app')
